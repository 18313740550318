const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "4b302e65b66a5ad97af0a34d29e85ac915487e87";
const CI_COMMIT_SHORT_SHA = "4b302e65";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2024-11-16T01:14:20Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
