import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "./card";
import { AdvertisementWrapper } from "./styles";

import { Carousel } from "components/carousel";
import type { ISlide } from "components/carousel/types";
import { useCloudinaryImage } from "hooks";

interface IData {
  description: string;
  publicId: string;
  title: string;
}

const Advertisement: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const backgroundImage = useCloudinaryImage({
    publicId: "integrates/login/bannerLogin",
  });

  const data: IData[] = useMemo(
    (): IData[] => [
      {
        description: "login.advertisements.content1.description",
        publicId: "integrates/login/advertisement/content1v4",
        title: "login.advertisements.content1.title",
      },
      {
        description: "login.advertisements.content2.description",
        publicId: "integrates/login/advertisement/content2",
        title: "login.advertisements.content2.title",
      },
      {
        description: "login.advertisements.content3.description",
        publicId: "integrates/login/advertisement/content3",
        title: "login.advertisements.content3.title",
      },
      {
        description: "login.advertisements.content4.description",
        publicId: "integrates/login/advertisement/content4",
        title: "login.advertisements.content4.title",
      },
    ],
    [],
  );

  const slides = useMemo(
    (): ISlide[] =>
      data.map(
        ({ description, publicId, title }: IData): ISlide => ({
          content: (
            <Card
              description={t(description)}
              imagePublicId={publicId}
              title={t(title)}
            />
          ),
        }),
      ),
    [data, t],
  );

  return (
    <AdvertisementWrapper url={backgroundImage.toURL()}>
      <Carousel interval={80} slides={slides} />
    </AdvertisementWrapper>
  );
};

export { Advertisement };
