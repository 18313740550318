import React from "react";
import { Helmet } from "react-helmet";

import { BigScreenLogin } from "./big-screen";

import { setReferrerURL } from "utils/pageSenseEvents";

const Login: React.FC = (): JSX.Element => {
  const referrerURL = document.referrer;
  setReferrerURL(referrerURL);

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Login | Fluid Attacks"}</title>
      </Helmet>
      <BigScreenLogin />
    </React.Fragment>
  );
};

export { Login };
