import type { Ref } from "react";
import React, { forwardRef } from "react";

import type { IDivider } from "./types";

import { theme } from "components/colors";
import { Container } from "components/container";

const Divider = forwardRef(function Divider(
  { color = theme.palette.gray[100], mt = 0.5, mb = 0.5, ...props }: IDivider,
  ref: Ref<HTMLHRElement>,
): JSX.Element {
  return (
    <Container
      as={"hr"}
      borderTop={`1px solid ${color}`}
      mb={mb}
      mt={mt}
      ref={ref}
      {...props}
    />
  );
});

export { Divider };
