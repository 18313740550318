import { styled } from "styled-components";

interface IHeaderContentProps {
  gap?: 0.5 | 2.25;
}

const HeaderContainer = styled.div<IHeaderContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ gap, theme }): string =>
    gap ? theme.spacing[gap] : theme.spacing[2.25]};
  align-self: stretch;
`;

const FormWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: 100vh;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing[2.25]};
`;

const LoginForm = styled.div`
  display: flex;
  max-width: 390px;
  min-width: 332px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }): string => theme.spacing[3]};
`;

export { HeaderContainer, FormContainer, LoginForm, FormWrapper };
