import { styled } from "styled-components";

const FormButton = styled.button`
  gap: 8px;
  background-color: ${({ theme }): string => theme.palette.white};
  width: 100%;
  max-width: 390px;
  height: 60px;
  border: 1px solid ${({ theme }): string => theme.palette.gray[300]};
  border-radius: ${({ theme }): string => theme.spacing[0.75]};
  padding: 0 ${({ theme }): string => theme.spacing[1.5]};
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }): string => theme.palette.gray[500]};
  font-family: ${({ theme }): string => theme.typography.type.primary};
  font-size: ${({ theme }): string => theme.typography.text.lg};
  font-style: normal;
  font-weight: ${({ theme }): string => theme.typography.weight.regular};
  line-height: ${({ theme }): string => theme.spacing[1.75]};

  box-shadow: 0 0 0 0 ${({ theme }): string => theme.palette.gray[600]};

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }): string => theme.palette.gray[600]};
    border: none;
  }
`;

export { FormButton };
