import React, { useMemo } from "react";

import { LoginProvider } from "./login-provider";
import type { IProvider } from "./login-provider/types";
import { FormButtonList } from "./styles";

interface ILoginProvidersProps {
  readonly section: "Login" | "Signup";
}

const GOOGLE_PROVIDER = "Google";
const AZURE_PROVIDER = "Azure";
const BITBUCKET_PROVIDER = "Bitbucket";

const LoginProviders: React.FC<ILoginProvidersProps> = ({
  section,
}: ILoginProvidersProps): JSX.Element => {
  const authProviders = useMemo(
    (): IProvider[] => [
      {
        imageAlt: "google-auth",
        imageId: "integrates/login/loginGoogleLogo",
        name: "Google",
        provider: GOOGLE_PROVIDER,
        redirectUrl: "/dglogin",
      },
      {
        imageAlt: "microsoft-oauth",
        imageId: "integrates/login/loginMicrosoftLogo",
        name: "Microsoft",
        provider: AZURE_PROVIDER,
        redirectUrl: "/dalogin",
      },
      {
        imageAlt: "bitbucket-oauth",
        imageId: "integrates/login/loginBitBucketLogo",
        name: "Bitbucket",
        provider: BITBUCKET_PROVIDER,
        redirectUrl: "/dblogin",
      },
    ],
    [],
  );

  const providers: JSX.Element[] = useMemo(
    (): JSX.Element[] =>
      authProviders.map(
        ({
          imageAlt,
          imageId,
          name,
          provider,
          redirectUrl,
        }: IProvider): JSX.Element => (
          <LoginProvider
            imageAlt={imageAlt}
            imageId={imageId}
            key={imageAlt}
            name={name}
            provider={`${section} ${provider}`}
            redirectUrl={redirectUrl}
          />
        ),
      ),
    [authProviders, section],
  );

  return <FormButtonList>{providers}</FormButtonList>;
};

export { LoginProviders };
