import type { FetchResult } from "@apollo/client";
import type { ExecutionResult, GraphQLError } from "graphql";
import { isEmpty, isFunction, isNil, isUndefined } from "lodash";
import type { useNavigate } from "react-router-dom";
import type { SubscriptionObserver } from "zen-observable-ts";

import type { IFilter } from "components/filter/types";
import { msgError } from "utils/notifications";
import { translate } from "utils/translations/translate";

const getErrors = <Type,>(
  results: FetchResult<Type>[],
): (readonly GraphQLError[])[] =>
  results
    .map((result): readonly GraphQLError[] | undefined =>
      "errors" in result ? result.errors : undefined,
    )
    .filter(
      (
        optionalErrors: readonly GraphQLError[] | undefined,
      ): optionalErrors is readonly GraphQLError[] =>
        !isUndefined(optionalErrors),
    );

const operationObservSubscribeComplete = (
  isForwarded: boolean,
  initialHistoryState: Record<string, unknown> | null,
  observer: SubscriptionObserver<FetchResult>,
  finalHistoryState: Record<string, unknown> | null,
): void => {
  if (isForwarded && initialHistoryState?.key === finalHistoryState?.key) {
    observer.complete.bind(observer)();
  }
};

type TNavigate = ReturnType<typeof useNavigate>;

const handleSkipForwarding = (
  skipForwarding: (() => void) | undefined,
  response?: ExecutionResult,
): void => {
  if (response !== undefined) {
    if (isFunction(skipForwarding)) {
      skipForwarding();
    }
  }
};

const handleGraphQLError = (
  error: GraphQLError,
  navigate: TNavigate,
  skipForwarding: (() => void) | undefined,
  response?: ExecutionResult,
): void => {
  switch (error.message) {
    case "Login required":
    case "Exception - User token has expired":
      handleSkipForwarding(skipForwarding, response);
      location.assign("/logout");
      break;
    case "Access denied":
    case "Access denied or group not found":
    case "Access denied or tag not found":
    case "Access denied or organization not found":
    case "Exception - Event not found":
    case "Exception - Group does not exist":
      handleSkipForwarding(skipForwarding, response);
      msgError(translate.t("groupAlerts.accessDenied"));
      navigate("/home");
      break;
    case "is_under_review":
      handleSkipForwarding(skipForwarding, response);
      msgError(
        translate.t("group.frozen.description"),
        translate.t("group.frozen.title", { groupName: "" }),
      );
      navigate("/home");
      break;
    default:
    // Propagate
  }
};

const unformatFilterValues = <T extends object>(
  value: IFilter<T>,
  filterNamesByIds: Partial<Record<string, string[] | string>>,
): Record<string, unknown> => {
  const unformat = (): Record<string, unknown> => {
    const titleFormat = filterNamesByIds[value.id];
    if (titleFormat === undefined) {
      return {};
    }
    if (typeof titleFormat === "string")
      return isNil(value.value) || isEmpty(value.value)
        ? { [titleFormat]: undefined }
        : {
            [titleFormat]: ["true", "false"].includes(value.value)
              ? JSON.parse(value.value)
              : value.value,
          };

    return {
      [titleFormat[0]]: value.rangeValues?.[0],
      [titleFormat[1]]: value.rangeValues?.[1],
    };
  };

  return unformat();
};
const getFiltersToSearch = <T extends object>(
  filters: IFilter<T>[],
  filterNamesByIds: Partial<Record<keyof T, string[] | string>>,
): Record<string, unknown> =>
  filters.reduce((prev, curr): Record<string, string> => {
    const currentValue = unformatFilterValues(curr, filterNamesByIds);

    return {
      ...prev,
      ...currentValue,
    };
  }, {});

const sleep = async (ms: number): Promise<void> =>
  new Promise((resolve): void => {
    setTimeout(resolve, ms);
  });

export {
  sleep,
  getErrors,
  handleGraphQLError,
  operationObservSubscribeComplete,
  getFiltersToSearch,
};
