import { styled } from "styled-components";

interface IAdvertisementWrapperProps {
  url: string;
}

const AdvertisementWrapper = styled.div<IAdvertisementWrapperProps>`
  flex: 1 0 0;
  display: flex;
  padding: 0 73px;
  border-radius: ${({ theme }): string => theme.spacing[1.5]};
  background: url(${({ url }): string => url}) center center / cover no-repeat;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  max-height: calc(100vh - 64px);
  min-height: 700px;

  @media (width <= 1024px) {
    padding: 0 52px;
  }

  @media (width <= 860px) {
    padding: 0 42px;
  }

  @media (width <= 768px) {
    display: none;
  }
`;

export { AdvertisementWrapper };
