import type { Event } from "@bugsnag/core";
import type { Error } from "@bugsnag/core/types/event";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import type {
  BugsnagErrorBoundary,
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import { CoralogixRum } from "@coralogix/browser";
import _ from "lodash";
import React, { Fragment } from "react";

import { Logger } from "./logger";

import { CI_COMMIT_SHORT_SHA } from "utils/ctx";
import { getEnvironment } from "utils/environment";

const noSpaceLeftOnDevice = (error: Error): boolean => {
  /*
   * In this array should be added all errors related
   * to the space in the device
   */
  const expectedErrors: string[] = ["NS_ERROR_FILE_NO_DEVICE_SPACE"];

  return _.includes(expectedErrors, error.errorClass);
};

Bugsnag.start({
  apiKey: "99a64555a50340cfa856f6623c6bf35d",
  appVersion: "integrates_version",
  onError: (event: Event): boolean => {
    event.errors.forEach((error: Error): void => {
      const message: string | undefined = event.context;
      // eslint-disable-next-line functional/immutable-data
      event.context = error.errorMessage;
      // eslint-disable-next-line functional/immutable-data
      error.errorMessage = _.isString(message) ? message : "";
      // eslint-disable-next-line functional/immutable-data
      event.groupingHash = event.context;
    });
    // Custom handling to space device errors
    if (noSpaceLeftOnDevice(event.errors[0])) {
      Logger.error("noSpaceLeftOnDevice", event);

      return false;
    }

    return true;
  },
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: getEnvironment(),
});

CoralogixRum.init({
  application: "integrates-front",
  coralogixDomain: "US2",
  maskClass: "mask-data",
  maskInputTypes: ["password", "text", "number", "tel"],
  // eslint-disable-next-line camelcase
  public_key: "cxtp_VXUuXp9CF0v2Lwx5FIYy486cpey0UQ",
  sessionRecordingConfig: {
    autoStartSessionRecording: false,
    blockClass: "sr-block",
    enable: true,
    maskInputOptions: { password: true, tel: true },
    recordConsoleEvents: true,
  },
  version: CI_COMMIT_SHORT_SHA,
});

const reactPlugin: BugsnagPluginReactResult | undefined =
  Bugsnag.getPlugin("react");

const bugsnagErrorBoundary: BugsnagErrorBoundary = _.isUndefined(reactPlugin)
  ? Fragment
  : reactPlugin.createErrorBoundary(React);

export { bugsnagErrorBoundary as BugsnagErrorBoundary };
