import { keyframes, styled } from "styled-components";

const clientsAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-188px * 15)); }
`;

const ClientsContainer = styled.div`
  overflow: hidden;
`;

const SlideShow = styled.div`
  display: flex;
  gap: 48px;
  animation: ${clientsAnimation} 50s linear infinite;
  width: calc(188px * 30);
`;

export { ClientsContainer, SlideShow };
