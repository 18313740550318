import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import { FormButton } from "./styles";
import type { IProvider } from "./types";

import { CloudImage } from "components/cloud-image";

const LoginProvider: React.FC<IProvider> = ({
  imageAlt,
  imageId,
  name,
  provider,
  redirectUrl,
}: IProvider): JSX.Element => {
  const handleProviderLogin = useCallback((): void => {
    mixpanel.track(provider);
    window.location.assign(redirectUrl);
  }, [provider, redirectUrl]);

  return (
    <FormButton key={imageAlt} onClick={handleProviderLogin}>
      <CloudImage alt={imageAlt} publicId={imageId} width={"24px"} />
      {`Continue with ${name}`}
    </FormButton>
  );
};

export { LoginProvider };
