import { placeholder } from "@cloudinary/react";
import React from "react";
import { useTheme } from "styled-components";

import { CloudImage } from "components/cloud-image";
import { Container } from "components/container";
import { Heading, Text } from "components/typography";

interface ICardProps {
  readonly description: string;
  readonly imagePublicId: string;
  readonly title: string;
}

const Card: React.FC<ICardProps> = ({
  description,
  imagePublicId,
  title,
}: ICardProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Container maxWidth={"766px"}>
      <CloudImage
        plugins={[placeholder()]}
        publicId={imagePublicId}
        width={"100%"}
      />
      <Container ml={0.75} mr={0.75}>
        <Heading
          color={"white"}
          size={"md"}
          spacing={2.5}
          textAlign={"center"}
          weight={"bold"}
          wordBreak={"break-word"}
        >
          {title}
        </Heading>
        <Text
          color={theme.palette.gray[100]}
          mt={0.75}
          size={"md"}
          spacing={1.5}
          textAlign={"center"}
          wordBreak={"break-word"}
        >
          {description}
        </Text>
      </Container>
    </Container>
  );
};

export { Card };
