import { styled } from "styled-components";

interface ICarouselWrapperProps {
  $currentIndex: number;
}

interface INavButtonProps {
  $isActive: boolean;
}

const CarouselContainer = styled.div`
  position: relative;
  margin: auto;
  overflow: hidden;
`;

const CarouselWrapper = styled.div<ICarouselWrapperProps>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(
    ${({ $currentIndex }): number => -$currentIndex * 100}%
  );
`;

const CarouselSlide = styled.div`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavButton = styled.button<INavButtonProps>`
  width: ${({ theme }): string => theme.spacing[0.75]};
  height: ${({ theme }): string => theme.spacing[0.75]};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ $isActive, theme }): string =>
    $isActive ? theme.palette.primary[400] : theme.palette.white};
  border: none;
`;

const NavContainer = styled.div`
  gap: ${({ theme }): string => theme.spacing[0.5]};
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  CarouselContainer,
  CarouselWrapper,
  CarouselSlide,
  NavContainer,
  NavButton,
};
