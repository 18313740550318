const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "9efca94b90774a74463826b32d7e33e5237bb418";
const CI_COMMIT_SHORT_SHA = "9efca94b";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2024-12-14T04:00:17Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
