import type { FC } from "react";
import React, { useCallback } from "react";

import {
  CarouselContainer,
  CarouselSlide,
  CarouselWrapper,
  NavButton,
  NavContainer,
} from "./styles";
import type { ICarouselProps } from "./types";

import { useCarousel } from "hooks/use-carousel";

const Carousel: FC<ICarouselProps> = ({
  slides,
  interval = 300,
}: Readonly<ICarouselProps>): JSX.Element => {
  const { cycle, setCycle, setProgress } = useCarousel(interval, slides.length);

  const handleNavClick = useCallback(
    (index: number): (() => void) =>
      (): void => {
        setCycle(index);
        setProgress(0);
      },
    [setCycle, setProgress],
  );

  return (
    <CarouselContainer>
      <CarouselWrapper $currentIndex={cycle}>
        {slides.map((slide, index): JSX.Element => {
          const key = `slide-${index}`;

          return <CarouselSlide key={key}>{slide.content}</CarouselSlide>;
        })}
      </CarouselWrapper>
      <NavContainer>
        {slides.map((_, index): JSX.Element => {
          const key = `nav-button-${index}`;

          return (
            <NavButton
              $isActive={index === cycle}
              data-testid={index === cycle ? "active" : "inactive"}
              key={key}
              onClick={handleNavClick(index)}
            />
          );
        })}
      </NavContainer>
    </CarouselContainer>
  );
};

export { Carousel };
