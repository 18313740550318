/* eslint-disable functional/immutable-data
  -------
  We need it in order to use methods from xhr and mutate some values from a
  graphQL error response.
*/
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import type {
  FetchResult,
  NextLink,
  NormalizedCacheObject,
  Operation,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  getMainDefinition,
  relayStylePagination,
} from "@apollo/client/utilities";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import type {
  ExecutionResult,
  FragmentDefinitionNode,
  GraphQLError,
  OperationDefinitionNode,
} from "graphql";
import { Kind, OperationTypeNode } from "graphql";
import { createClient } from "graphql-ws";
import _ from "lodash";
import { createElement, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { Subscription, SubscriptionObserver } from "zen-observable-ts";

import {
  handleGraphQLError,
  operationObservSubscribeComplete,
} from "./helpers";

import { getEnvironment } from "utils/environment";
import { Logger } from "utils/logger";
import { msgError } from "utils/notifications";
import { translate } from "utils/translations/translate";

interface IHandledErrorAttr {
  graphQLErrors?: readonly GraphQLError[];
  networkError?: Error | ServerError | ServerParseError;
  skipForwarding?: () => void;
  response?: ExecutionResult;
  operation: Operation;
  forward: NextLink;
}

type TErrorHandlerAttr = (error: IHandledErrorAttr) => void;

const getCookie = (name: string): string => {
  if (document.cookie !== "") {
    const cookies: string[] = document.cookie.split(";");
    const cookieValue: string | undefined = cookies.find(
      (cookie: string): boolean => cookie.trim().startsWith(`${name}=`),
    );
    if (!_.isUndefined(cookieValue)) {
      return decodeURIComponent(cookieValue.trim().substring(name.length + 1));
    }
  }

  return "";
};

/**
 * Apollo-compatible wrapper for XHR requests
 *
 * This is a necessary workaround for file upload mutations
 * since the Fetch API that apollo uses by default
 * lacks support for tracking upload progress
 *
 * @see https://github.com/jaydenseric/apollo-upload-client/issues/88
 */
interface IExtendedFetchConfig extends RequestInit {
  notifyUploadProgress: boolean;
  onUploadProgress: (ev: ProgressEvent) => void;
}

const xhrWrapper: WindowOrWorkerGlobalScope["fetch"] = async (
  uri: string,
  options: IExtendedFetchConfig,
): Promise<Response> =>
  new Promise(
    (
      resolve: (value: Response) => void,
      reject: (reason: Error) => void,
    ): void => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onload = (): void => {
        resolve(new Response(xhr.response, options));
      };

      xhr.onerror = (): void => {
        reject(new Error(`Network request failed: ${xhr.responseText}`));
      };

      xhr.ontimeout = (): void => {
        reject(new Error("Network request timed out"));
      };

      xhr.open(_.get(options, "method", "POST"), uri, true);

      if (options.headers !== undefined) {
        Object.keys(options.headers).forEach((key: string): void => {
          xhr.setRequestHeader(key, _.get(options.headers, key) as string);
        });
      }

      xhr.upload.onprogress = options.onUploadProgress;

      xhr.send(options.body as XMLHttpRequestBodyInit);
    },
  );

const extendedFetch: WindowOrWorkerGlobalScope["fetch"] = async (
  uri: string,
  options: IExtendedFetchConfig,
): Promise<Response> =>
  options.notifyUploadProgress ? xhrWrapper(uri, options) : fetch(uri, options);

const httpLink = createUploadLink({
  credentials: "same-origin",
  fetch: extendedFetch,
  headers: {
    "X-CSRFToken": getCookie("csrftoken"),
    accept: "application/json",
  },
  uri: `${window.location.origin}/api`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    connectionAckWaitTimeout: Infinity,
    keepAlive: 10000,
    shouldRetry: (): boolean => true,
    url: `wss://${window.location.host}/api`,
  }),
);

const apiLink: ApolloLink = ApolloLink.split(
  ({ query }: Operation): boolean => {
    const definition: FragmentDefinitionNode | OperationDefinitionNode =
      getMainDefinition(query);

    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    );
  },
  wsLink,
  httpLink,
);

const retryLink: ApolloLink = new RetryLink({
  attempts: (count, operation, error): boolean => {
    const MAX_QUERY_ATTEMPTS = 10;
    const MAX_MUTATION_ATTEMPTS = 5;

    if (error !== undefined) {
      if (
        operation.query.definitions.length > 0 &&
        String(_.get(operation.query.definitions[0], "operation", "query")) ===
          "mutation"
      ) {
        return count <= MAX_MUTATION_ATTEMPTS;
      }

      return count <= MAX_QUERY_ATTEMPTS;
    }

    return false;
  },
  delay: {
    initial: 300,
    jitter: true,
    max: Infinity,
  },
});

const validateSubscription = (subscription: Subscription | undefined): void => {
  if (subscription !== undefined) {
    subscription.unsubscribe();
  }
};

const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError,
): void => {
  const { statusCode } = networkError as { statusCode?: number };
  const forbidden = 403;
  const notAllowed = 405;
  const requestConflict = 409;
  const tooManyRequests = 429;
  const gatewayTimeout = 504;

  switch (statusCode) {
    case undefined:
      msgError(translate.t("groupAlerts.errorNetwork"), "Offline");
      break;
    case forbidden:
      // Django CSRF expired
      location.reload();
      break;
    case notAllowed:
      msgError(
        translate.t("groupAlerts.methodNotAllowed"),
        "Method Not Allowed",
      );
      break;
    case requestConflict:
      msgError(translate.t("groupAlerts.requestConflict"), "Request Conflict");
      break;
    case tooManyRequests:
      msgError(translate.t("groupAlerts.tooManyRequests"), "Too Many Requests");
      Logger.warning("Too Many Requests", { ...networkError });
      break;
    case gatewayTimeout:
      msgError(translate.t("groupAlerts.gatewayTimeout"));
      Logger.warning("Gateway Timeout", { ...networkError });
      break;
    default:
      msgError(translate.t("groupAlerts.errorTextsad"));
      Logger.warning("A network error occurred", { ...networkError });
  }
};

/**
 * Custom error link implementation to prevent propagation
 * of handled network errors
 * @see https://github.com/apollographql/react-apollo/issues/1548
 * @see https://github.com/apollographql/apollo-link/issues/855
 */
const onError = (errorHandler: TErrorHandlerAttr): ApolloLink =>
  new ApolloLink(
    (operation: Operation, forward: NextLink): Observable<FetchResult> =>
      new Observable(
        (observer: SubscriptionObserver<FetchResult>): (() => void) => {
          const subscription: Subscription | undefined = (():
            | Subscription
            | undefined => {
            try {
              const skipGlobalErrorHandler: boolean =
                typeof operation.getContext().skipGlobalErrorHandler ===
                "boolean"
                  ? operation.getContext().skipGlobalErrorHandler
                  : false;
              const operationObserver: Observable<FetchResult> =
                forward(operation);
              // It is necessary to change the variable value
              // eslint-disable-next-line functional/no-let
              let isForwarded = true;
              const skipForwarding = (): void => {
                isForwarded = false;
              };
              const initialHistoryState: Record<string, unknown> | null =
                history.state;

              return operationObserver.subscribe({
                complete: (): void => {
                  const finalHistoryState: Record<string, unknown> | null =
                    history.state;
                  operationObservSubscribeComplete(
                    isForwarded,
                    initialHistoryState,
                    observer,
                    finalHistoryState,
                  );
                },
                error: (networkError): void => {
                  errorHandler({
                    forward,
                    networkError,
                    operation,
                  });
                },
                next: (result: FetchResult): void => {
                  if (result.errors !== undefined && !skipGlobalErrorHandler) {
                    errorHandler({
                      forward,
                      graphQLErrors: result.errors,
                      operation,
                      response: result,
                      skipForwarding,
                    });
                  }
                  // IsForwarded can change its value

                  if (isForwarded) {
                    observer.next(result);
                  }
                },
              });
            } catch (exception: unknown) {
              errorHandler({
                forward,
                networkError: exception as Error,
                operation,
              });

              return undefined;
            }
          })();

          return (): void => {
            validateSubscription(subscription);
          };
        },
      ),
  );

type TNavigate = ReturnType<typeof useNavigate>;
// Top-level error handling
const errorLink = (navigate: TNavigate): ApolloLink =>
  onError(
    ({
      graphQLErrors,
      networkError,
      response,
      skipForwarding,
    }: IHandledErrorAttr): void => {
      if (networkError !== undefined) {
        handleNetworkError(networkError);
      } else if (graphQLErrors !== undefined) {
        graphQLErrors.forEach((error): void => {
          handleGraphQLError(error, navigate, skipForwarding, response);
        });
      }
    },
  );

/**
 * Load cache with union type definitions
 * @see https://www.apollographql.com/docs/react/v2.6/data/fragments/#fragments-on-unions-and-interfaces
 */
const getCache = (): InMemoryCache =>
  new InMemoryCache({
    possibleTypes: {
      Root: ["GitRoot", "IPRoot", "URLRoot"],
    },
    typePolicies: {
      Finding: {
        fields: {
          draftsConnection: relayStylePagination(),
          vulnerabilities: relayStylePagination(),
          vulnerabilitiesConnection: relayStylePagination(["state"]),
          zeroRiskConnection: relayStylePagination(),
        },
        keyFields: ["id"],
      },
      GitEnvironmentUrl: {
        keyFields: ["id", "rootId"],
      },
      Group: {
        fields: {
          forcesExecutionsConnection: relayStylePagination(),
          gitRoots: relayStylePagination(),
          ipRoots: relayStylePagination(),
          toeInputs: relayStylePagination(),
          toeLines: relayStylePagination(),
          toeLinesConnection: relayStylePagination(),
          toePackages: relayStylePagination(),
          urlRoots: relayStylePagination(),
          vulnerabilities: relayStylePagination(),
          vulnerabilityDrafts: relayStylePagination(["state"]),
        },
        keyFields: ["name"],
      },
      Me: {
        fields: {
          drafts: relayStylePagination(),
          findingEmptyEvidence: relayStylePagination(),
          findingEvidenceDrafts: relayStylePagination(),
          findingReattacks: relayStylePagination(),
          vulnerabilityDrafts: relayStylePagination([
            "findingTitle",
            "fromReportDate",
            "groupName",
            "hacker",
            "maxSeverityTemporalScore",
            "minSeverityTemporalScore",
            "organizationName",
            "sourceType",
            "state",
            "toReportDate",
          ]),
        },
        keyFields: ["userEmail"],
      },
      Organization: {
        fields: { integrationRepositoriesConnection: relayStylePagination() },
        keyFields: ["name"],
      },
      Vulnerability: {
        fields: { historicTreatmentConnection: relayStylePagination() },
        keyFields: ["id"],
      },
    },
  });

type TProviderProps = Omit<
  React.ComponentProps<typeof BaseApolloProvider>,
  "client"
>;
const ApolloProvider: React.FC<TProviderProps> = (
  props: TProviderProps,
): JSX.Element => {
  const navigate = useNavigate();
  const client: ApolloClient<NormalizedCacheObject> = useMemo(
    (): ApolloClient<NormalizedCacheObject> =>
      new ApolloClient({
        cache: getCache(),
        connectToDevTools: getEnvironment() !== "production",
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
          },
        },
        link: ApolloLink.from([errorLink(navigate), retryLink, apiLink]),
      }),
    // This computed value will never change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return createElement(BaseApolloProvider, { client, ...props });
};

export { getCache, ApolloProvider };
