import { Link } from "react-router-dom";
import { styled } from "styled-components";

interface IStyledLinkProps {
  color?: string;
}

const LoginGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  gap: ${({ theme }): string => theme.spacing[2]};
  padding: ${({ theme }): string => theme.spacing[2]};

  @media (width <= 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledLink = styled(Link)<IStyledLinkProps>`
  color: ${({ color, theme }): string => color ?? theme.palette.gray[400]};
  text-decoration: underline;
`;

export { LoginGrid, StyledLink };
