import { styled } from "styled-components";

const FormButtonList = styled.div`
  width: 100%;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export { FormButtonList };
